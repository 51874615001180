
import axios from '../../plugins/_axios'
import Vue from 'vue'


export const state = {
    password: null,
    token: null,
    username: null,
};
export const mutations = {
    clearAuth(state) {
        state.username = null
        state.password = null
        state.token = null
    },
    setToken(state, token) {
        state.token = token
    }
    // setUsername(state, username) {
    //     state.username = username
    // },
    // setPassword(state, password) {
    //     state.password = password
    // },
};
export const actions = {
    connect({ commit }, { username, password }) {
        const token = btoa(`${username}:${password}`)

        return axios.get('/auth/test', { headers: { 'Authorization': `Basic ${token}` } }).then(res => {
            Vue.$log.info('Login test success');
            if (res.status == 204)
                commit('setToken', token)
            return Promise.resolve(res.status)
        }).catch(() => {
            Vue.$log.info('Login test rejected');
            return Promise.reject(401)

        })
    },
    logout({ commit }) {
        return new Promise(resolve => {
            commit('clearAuth');
            resolve()
        })
    }
};
export const getters = {
    isAuthenticated: (state) => state.token != null,
    showLogin: (state) => (state.username == null && state.password == null),
    token: (state) => state.token
};