<template>
    <v-system-bar height="30" color="white">
        <v-btn icon plain :to="{name: 'Home'}" exact-active-class="bb">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-menu open-on-hover bottom>
            <template v-slot:activator="{on, attrs}">
                <v-btn icon v-on="on" v-bind="attrs">
                    <v-icon>mdi-cog</v-icon>
                </v-btn>
            </template>
            <v-list dense class="py-0">
                <v-list-item :to="{name: 'StudentList'}" dense>
                    <v-list-item-avatar class="my-0">
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>{{$t('header.manageStudents')}}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name: 'BookList'}" dense>
                    <v-list-item-avatar class="my-0">
                        <v-icon>mdi-book-open-blank-variant</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>{{$t('header.manageBooks')}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-spacer></v-spacer>
    </v-system-bar>
</template>
<script>
export default {
}
</script>