import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import PlaceholderLayout from '../components/Layout/PlaceholderLayout.vue'
import LoginView from '../views/Login.vue'
import AppLayout from '../components/Layout/AppLayout.vue'
import authMiddleware from './middlewares/auth-middleware';
import store from '../store'
//import DialogRouterLayout from '../components/Layout/DialogRouterLayout.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/',
        component: AppLayout,
        meta: {
            middleware: [authMiddleware],
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                name: 'Home',
                component: HomeView
            },
            {
                path: '/students',
                component: PlaceholderLayout,
                children: [
                    {
                        path: '',
                        name: 'StudentList',
                        component: () => import('../views/StudentList.vue'),

                    },
                    {
                        path: 'create',
                        name: 'NewStudent',
                        component: () => import('../views/StudentDetails.vue'),
                        meta: { transitionName: 'slide' }
                    },
                    {
                        path: ":id",
                        name: "StudentDetails",
                        component: () => import('../views/StudentDetails.vue'),
                        meta: { transitionName: 'slide' }
                    },

                ]
            },
            {
                path: '/books',
                component: PlaceholderLayout,
                children: [
                    {
                        path: '',
                        name: 'BookList',
                        component: () => import('../views/BookList.vue')
                    },
                    {
                        path: 'create',
                        name: 'NewBook',
                        component: () => import('../views/BookDetails.vue'),
                        meta: { transitionName: 'slide' }
                    },
                    {
                        path: ":id",
                        name: "BookDetails",
                        component: () => import('../views/BookDetails.vue'),
                        meta: { transitionName: 'slide' }
                    },
                ]
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {

    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    store.dispatch('init').then(() => {
        var middlewares = to.matched
            .filter(x => Object.keys(x.meta).some(y => y == 'middleware'))
            .map(x => x.meta.middleware);
        middlewares = middlewares.flatMap(x => x);
        middlewares = middlewares.filter((value, index, self) => self.indexOf(value) === index);
        if (middlewares.length) {

            // const middleware =Array.isArray(to.meta.middleware)
            //     ? to.meta.middleware
            //     : [to.meta.middleware];

            const context = {
                from,
                next,
                router,
                to,
            };
            const nextMiddleware = nextFactory(context, middlewares, 1);

            return middlewares[0]({ ...context, next: nextMiddleware });
        }

        return next();
    })
});

export default router
