<template>
    <!-- <v-dialog v-model="show" persistent max-width="50%"> -->
    <v-card elevation="0">
        <v-card-title>
            <v-toolbar elevation="0">
                <v-btn icon @click="onBack">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="6" offset="3">
                    <template v-if="isNewBook">
                        <v-alert type="info">{{$t('studentPicker.isNewBook')}}</v-alert>
                        <!-- <v-text-field :label="$t('studentPicker.newBookTitle.label')" outlined dense persistent-placeholder></v-text-field> -->
                    </template>

                    <h2 v-else>{{bookTitle}}</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" offset="3">
                    <v-text-field v-if="true == false" outlined dense hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" offset="3">
                    <v-btn v-if="isRented" depressed block @click="onReturnBook">{{$t('studentPicker.returnBook')}}</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" offset="3">
                    <v-list tile>
                        <v-list-item link v-for="student in students" :key="student.id" class="my-1" :disabled="student.id == rentedTo" :class="{'blue-grey lighten-5': student.id == rentedTo}" @click="onSelectStudent(student.id)">
                            <v-list-item-content>
                                <v-list-item-title>{{student.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <!-- </v-dialog> -->
</template>
<script>
export default {
    props: {
        book: {
            type: Object,
            required: true
        },
        value: {
            type: Array,
            required:true
        }
    },
    data() {
         return {
             show: false,
             students: this.value
         }
    },
    computed: {
        bookTitle() {
            return this.book.title || ''
        },
        isNewBook() {
            var isNew = this.book.createdAt == null
            console.log(isNew)
            return isNew
        },
        isRented() {
            return this.rentedTo != null
        },
        rentedTo() {
            return this.book.rentedTo
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.students = v
            }
        }
    },
    methods: {
        onBack() {
            this.$emit('click:back')
        },
        onReturnBook() {
            this.$emit('click:returnBook')
        },
        onSelectStudent(studentId) {
            this.$emit('click:rentBook', {studentId});
        },
    }
}
</script>