<template>
    <v-row class="h-100" align="center">
        <v-col v-if="!showForm" cols="6" offset="3" class="text-center">
            <v-icon x-large>mdi-lock</v-icon>
        </v-col>
        <transition name="slide-fade" v-if="showForm">
            <v-col cols="8">
                <v-img :src="bgImageSrc">
                    <div class="fill-height gradient-display"></div>
                </v-img>
            </v-col>
        </transition>
        <transition name="slide-fade" v-if="showForm">
            <v-col cols="4">
                <v-alert v-if="showError" type="error" width="100%" icon="mdi-lock">{{$t('loginDialog.cantConnect')}}</v-alert>
                <v-card light width="450px" elevation="0">
                    <v-form v-model="form" @submit.prevent="onConnect">
                        <v-card-text class="pb-0">
                            <v-text-field v-model="credentials.username" :label="$t('loginDialog.username.label')" dense outlined persistent-placeholder :rules="[(v) => !!v || $t('loginDialog.username.required')]" :disabled="isLoading"></v-text-field>
                            <v-text-field v-model="credentials.password" :label="$t('loginDialog.password.label')" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" counter dense outlined persistent-placeholder :rules="[(v) => !!v || $t('loginDialog.password.required'), (v => !!v && v.length >= 8 || $t('loginDialog.password.minLength'))]" @click:append="showPassword = !showPassword" :disabled="isLoading"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="pt-0">
                            <v-spacer></v-spacer>
                            <v-btn text type="submit" @click="onConnect" :disabled="!form" :loading="isLoading">{{$t('loginDialog.connectBtn')}}</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </transition>
    </v-row>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        credentials: {},
        form: null,
        isLoading: false,
        showError: false,
        showForm: false,
        showImage: false,
        showPassword: false,
    }),
    computed: {
        bgImageSrc() {
            return require('../assets/login-background.png')
        }
    },
    methods: {
        onConnect() {
            this.isLoading = true;
            this.$store.dispatch('auth/connect', {...this.credentials})
            .then(() => {
                this.$router.push({name: 'Home'});
            })
            .catch(() => {
                this.showError = true
            })
            .finally(() => {
                this.isLoading = false
            })
        }
    },
    mounted() {
        
        this.showForm = true
    }
}
</script>
<style  scoped>

.gradient-display{
    /* background: radial-gradient(closest-corner, rgba(255,255,255,0), rgba(255,255,255,1)); */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 1%, rgba(255,255,255,0) 95%, rgba(255,255,255,1) 100%),
                linear-gradient(to top, rgba(255,255,255,0) 1%, rgba(255,255,255,0) 95%, rgba(255,255,255,1) 100%),
                linear-gradient(to left, rgba(255,255,255,0) 1%, rgba(255,255,255,0) 95%, rgba(255,255,255,1) 100%),
                linear-gradient(to right, rgba(255,255,255,0) 1%, rgba(255,255,255,0) 95%, rgba(255,255,255,1) 100%);
}

.v-icon.mdi.mdi-lock {
    font-size: 150px !important;
}

.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
}

</style>