import VueLogger from 'vuejs-logger'
import Vue from 'vue'

const isProd = process.env.NODE_ENV === 'production'

const options = {
    isEnabled: true,
    logLevel: isProd ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColor: true
}

Vue.use(VueLogger, options)