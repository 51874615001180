import store from '../../store'

export default function auth({ to, next, router }) {
    var requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (!requiresAuth) {
        return next()
    }
    else {
        if (!store.getters['auth/isAuthenticated']) {
            store.dispatch('auth/logout')
            router.push({ name: 'Login' })
        }
        return next();
    }
}