import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import i18n from './i18n'
import _axios from './plugins/axios'
import logger from './plugins/logger'

const isProduction = process.env.NODE_ENV == 'production'
Vue.config.productionTip = false
Vue.use(_axios);
// Vue.mixin({
//     computed: {
//         $axios: {
//             get() {
//                 return _axios;
//             }
//         }
//     }
// })
if (isProduction) {
    console.clear();
    console.log("%cJE SAIS QUE TU REGARDES LE CODE", "color:red;font-size:24px;")
}
new Vue({
    router,
    store,
    vuetify,
    i18n,
    logger,
    render: h => h(App)
}).$mount('#app')

Vue.component('BookRentedAndCreated', () => import('./components/Shared/BookRentedAndCreated.vue'))
