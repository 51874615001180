<template>
    <v-stepper class="h-100" v-model="step" elevation="0">
        <v-stepper-items class="h-100">
            <v-stepper-content step="1" class="h-100">
                <v-row class="h-100" align="center">
                    <v-col cols="8" offset="2">
                        <v-text-field ref="bookInput" v-model="bookId" :label="$t('home.bookId.label')" :loading="loading" :disabled="loading" outlined persistent-placeholder @keyup.enter="submit"></v-text-field>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
                <student-picker v-model="students" :book="book" :rentedTo="book.rentedTo" :isNewBook="book.id == null" :bookTitle="book.title" @click:back="step = 1" @click:returnBook="onReturnBook" @click:rentBook="onRentBook"></student-picker>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>

import StudentPicker from '../components/Home/StudentPicker.vue'
export default {
  name: 'Home',
  components: {StudentPicker},
  data: () => ({
      bookId: null,
      book: {},
      loading: false,
      showPicker: false,
      step: 1,
      students: []
  }),
  methods: {
    focusInput() {
        this.$nextTick(() => {
            const theElement = this.$refs.bookInput.$el
            const input = theElement.querySelector('input:not([type=hidden]),textarea:not([type=hidden])')
            if (input) {
            setTimeout(() => {
                input.focus()
            }, 0)
            }
  });
    },
    loadStudents() {
        this.axios.get('/students').then(res => {
            const data = res.data.data
            this.students = data.map(x => ({id: x.id, name: x.name}))
        })
    },
    onRentBook({studentId, newBookTitle}) {
        this.axios.post(`/students/${studentId}/rentBook`, {isbn: this.book.isbn, newBookTitle})
        .then((res) => {
            if(res.status == 204) {
                this.$store.dispatch('snackbar/showBookRented');
            }
            else if(res.status == 200) {
                this.$store.dispatch('snackbar/showBookRentedAndCreated', {bookId: res.data.data.id})
            }
            
        })
        .catch(() => {
            console.warn('error renting book')
        })
        .finally(() => {
            this.step = 1
            this.book = {}
            this.focusInput()
        })
    },
    onReturnBook() {
        this.axios.delete(`/books/${this.book.id}`).then(() => {
            this.book = {}
            this.$store.dispatch('snackbar/showBookReturned')
            return Promise.resolve()
        })
        .finally(() => {
            this.step = 1
            this.book = {}
            this.focusInput()
        })
    },
    submit() {
        this.axios.get(`books/${this.bookId}`).then(res => {
            const data = res.data.data
            console.log(res.data)
            this.book = data;
            this.step = "2"
        }).catch((e) => {
            
            if(e.response.status == 404) {
                this.book = {isbn:this.bookId}
                this.step = 2
                return Promise.resolve();
            }
        }).finally(() => {
            this.bookId = null;
            this.focusInput()
        })
        
}
  },
  created() {
      this.loadStudents();
  },
  mounted() {
    this.focusInput();
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-stepper__wrapper {
    height: 100% !important;
    min-height: 100% !important;
}
</style>