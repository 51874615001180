import Vue from 'vue'
import Vuex from 'vuex'
//import { createLogger } from 'vuex'


Vue.use(Vuex);


// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((modules, [name, module]) => {
        if (module.namespaced === undefined) {
            module.namespaced = true
        }

        return { ...modules, [name]: module }
    }, {})

export default new Vuex.Store({
    //plugins: [createLogger({ logger: Vue.$log })],
    //plugins: [createPersistedState({ storage: window.localStorage, key: "vuex" })],
    modules,
    state: {
    },
    mutations: {
    },
    actions: {
        init({ dispatch }) {
            var inits = []
            Object.keys(modules).forEach(moduleName => {
                if ('actions' in modules[moduleName])
                    if ('init' in modules[moduleName].actions)
                        inits.push(dispatch(moduleName + '/init'))
            });
            return Promise.all(inits)
        }
    },
    getters: {
    }
})