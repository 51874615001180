<template>
    <v-sheet class="h-100">
        <app-header></app-header>
        <router-view></router-view>
    </v-sheet>
</template>
<script>
import AppHeader from './Header.vue'
export default {
    components: {AppHeader}
}
</script>