<template>
    <v-sheet>
        <v-snackbar :top="snackbar.top" v-if="showSnackbar" :value="showSnackbar" :transition="transition" :timeout="snackbar.timeout" @input="closeSnackbar(false)" :right="right" :left="left">
            <component v-if="snackbar.component != null" :is="snackbar.component" v-model="snackbar.params"></component>
            <span v-else>{{snackbar.text}}</span>

            <template v-slot:action="{attrs}">
                <v-btn v-if="useIcon" icon :color="snackbar.color" v-bind="attrs" @click="closeSnackbar(true)">
                    <v-icon>{{snackbar.closeIcon}}</v-icon>
                </v-btn>

                <v-btn v-else :color="snackbar.color" v-bind="attrs" @click="closeSnackbar(true)">{{closeText}}t</v-btn>
            </template>
        </v-snackbar>
    </v-sheet>
</template>
<script>
export default {
    props: {
        defaultCloseText: {
            type:String,
            required:false,
            default: "Close"
        }
    },
    components: {},
    data() {
         return {}
    },
    computed: {
        closeText() {
            return this.snackbar.closeText || this.defaultCloseText
        },
        left() {
            return this.snackbar.alignement === 'left'
        },
        right() {
            return this.snackbar.alignement === 'right'
        },
        showSnackbar: {
            get() {
                return this.$store.getters['snackbar/hasSnackbar'];
            }
        },
        snackbar() {
            return this.$store.getters['snackbar/snackbar'];
        },
        transition() {
            if(this.snackbar.top)
                return 'scroll-y-revers-transition'
            else
                return 'scroll-y-transition'
        },
        useIcon() {
            const icon = this.snackbar.closeIcon
            return icon != null && typeof icon === 'string' && icon.trim().length > 0
        }
    },
    watch: {},
    methods: {
        closeSnackbar(userInitiated) {
            this.$store.dispatch('snackbar/closeSnackbar', {userInitiated})
        }
    }
}
</script>