<template>
    <v-app>
        <!-- <app-header></app-header> -->
        <v-main>
            <v-container class="h-100">
                <!-- <transition :name="transitionName" :mode="transitionMode" :enter-active-class="transitionEnterActiveClass" @beforeLeave="beforeLeave" @enter="enter" @afterEnter="afterEnter"> -->

                <router-transition>
                    <router-view />
                </router-transition>
                <router-view name="dialog"></router-view>
                <!-- </transition> -->
            </v-container>
        </v-main>
        <snackbar-container :defaultCloseText="$t('app.close')"></snackbar-container>
    </v-app>
</template>
<script>
import './assets/global.scss'
//import AppHeader from './components/Layout/Header.vue'
import RouterTransition from './components/Layout/RouterTransition.vue'
import SnackbarContainer from './components/Layout/SnackbarContainer.vue'

export default {
  name: 'App',
  components: {RouterTransition, SnackbarContainer},
  

};
</script>
