"use strict";

import Vue from 'vue';
import _axios from "./_axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// let config = {
//     baseURL: process.env.VUE_APP_API_URL || ""
//     // timeout: 60 * 1000, // Timeout
//     //withCredentials: true, // Check cross-site Access-Control
// };

// const _axios = axios.create(config);
// _axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


// _axios.interceptors.request.use(request => {
//     request.headers.common['Access-Control-Allow-Origin'] = '*'
//     return request
// }
// );

// // Add a response interceptor
// _axios.interceptors.response.use(
//     function (response) {
//         // Do something with response data
//         return response;
//     },
//     function (error) {
//         // Do something with response error
//         return Promise.reject(error);
//     }
// );

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
