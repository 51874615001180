import i18n from '../../i18n';


const DefaultSnackbar = Object.seal({
    /** 
     * Text to show in snackbar
     * @type {string}
     */
    text: null,
    /**
     * The snackbar close by itself after a certain time
     * Set to -1 for infinite
     * @type {number}
     * @default 3000
     */
    timeout: 3000,
    /**
     * Can the user close the snackbar itself
     * @type {boolean}
     * @default true
     */
    closable: true,
    /**
     * text to show as close button. Not shown if closable is false.
     * @type {string}
     * @default null
     */
    closeText: null,
    /**
     * mdi icon to show for close button. Not shown if closable is false. Not shown if closeText has value
     * @type {string}
     * @default 'mdi-close'
     */
    closeIcon: 'mdi-close',
    /**
     * color of close text/icon
     * @type {string}
     * @default 'red'
     */
    color: 'red',
    /**
     * A callback to receive when the snackbar is close. 
     * function(id: string | snackbar id, userInitiated: boolean | was the close action user initiated)
     * @type {function}
     */
    closeCallback: () => { },//(id, userInitiated)=>{},
    /**
     * Provided by store; returned in action when adding snackbar
     * @type {string}
     */
    id: null,
    /**
     * Is the snackbar sitting on top
     * @default false
     */
    top: false,
    /**
     * Alignement of the snackbar
     * either 'left' or 'right'
     * @default null
     * @type {String}
     */
    alignement: null,
    /**
     * Use of a component. The component must be registred before.
     * Has priority over text
     * @default null
     * @type {String}
     */
    component: null,
    /**
     * Use when component  is set. Params content will be set as props using v-model
     * 
     * @default null
     * @type {Object}
     */
    params: null
});

function createSnackbar(text, timeout, closable, color, closeCallback, id, top, alignement, closeText, closeIcon, component, params) {
    var snackbar = { ...DefaultSnackbar, text, id }
    snackbar.timeout = timeout ? timeout : 3000//DefaultSnackbar.timeout
    // if(closeText != null || closeText.trim().length > 0)
    // {
    //     snackbar.closeText = closeText
    //     snackbar.closable = true
    // }
    snackbar.closable = typeof closable === 'boolean' ? closable : true// DefaultSnackbar.closable
    snackbar.color = color ? color : 'red'//DefaultSnackbar.color
    snackbar.closeCallback = closeCallback
    snackbar.top = typeof top === 'boolean' ? top : DefaultSnackbar.top
    snackbar.alignement = typeof alignement === 'string' ? alignement : DefaultSnackbar.alignement
    snackbar.closeText = closeText
    snackbar.closeIcon = closeIcon
    snackbar.component = component
    snackbar.params = params

    return snackbar
}

export const state = {
    snackbar: null
};
export const mutations = {
    CLEAR_SNACKBAR(state) {
        state.snackbar = null
    },
    SET_SNACKBAR(state, snackbar) {
        state.snackbar = snackbar
    }
};
export const actions = {
    addSnackbar({ commit, dispatch }, { text, timeout, closable, color, callback, top, alignement, closeText, closeIcon, component, params }) {
        return dispatch('closeSnackbar', { userInitiated: false })
            .then(() => {
                return new Promise((resolve) => {
                    var id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
                    var snackbar = createSnackbar(text, timeout, closable, color, callback, id, top, alignement, closeText, closeIcon, component, params)

                    commit('SET_SNACKBAR', snackbar)
                    resolve(id)
                })
            })
    },
    closeSnackbar({ commit, state }, { userInitiated }) {
        return new Promise((resolve) => {
            if (state.snackbar)
                if (state.snackbar.closeCallback != null)
                    state.snackbar.closeCallback(state.snackbar.id, userInitiated)
            commit('CLEAR_SNACKBAR')
            resolve()
        })
    },
    showBookReturned({ dispatch }) {
        return dispatch('addSnackbar', { text: i18n.t('app.message.bookReturned'), color: 'success', closeIcon: 'mdi-close', top: false })
    },
    showBookRented({ dispatch }) {
        return dispatch('addSnackbar', { text: i18n.t('app.message.bookRented'), color: 'success', closeIcon: 'mdi-close', top: false })
    },
    showBookRentedAndCreated({ dispatch }, { bookId }) {
        if (bookId == null || bookId <= 0)
            throw new Error('Book id cannot be null')
        return dispatch('addSnackbar', { component: 'BookRentedAndCreated', params: { bookId }, color: 'success', closeIcon: 'mdi-close', top: false });
    }
};
export const getters = {
    hasSnackbar: (state) => state.snackbar != null,
    snackbar: (state) => state.snackbar
};